import React, { useCallback, useEffect, useRef, useState } from 'react';
import './index.css';
import axios from 'axios';
import * as moment from 'jalali-moment'
import { widget } from '../../charting_library';
import UDFCompatibleDatafeed from '../../charting_library/datafeeds/udf/dist/bundle.js'
import { HmacSHA256 } from 'crypto-js';
const privateKey = "2baf9b8651666bf30f76f10aa1ed10b3481ee5fe8ecebb84ddd221cc59a0500a";
const text = "90af8a2940dc5a3ee651360b4bea7a2c::" + new Date().getUTCHours();
const key = HmacSHA256(text, privateKey);

function getLanguageFromURL() {
	const regex = new RegExp('[\\?&]lang=([^&#]*)');
	const results = regex.exec(window.location.search);
	return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}
var ac;
const base_url = "https://panel.chartshield.com/server";

var tvWidget
var bar_replay_set_speed = 1000;
var replay_current_pos = 0;
var bc;
const user_id = 102;
var resolution;
var cc = [];
var bar_replay_status = 0
var main_timer;
let symbols;
var bar_replay_controls_status = false
let state = {};
let state_end = 0;
let cc_length = 0;
let state_data = [];
var bar_replay_controls;
function tvBarTimer() {
	try {
		clearInterval(main_timer);
	} catch (e) {

	}
	let vcount = tvWidget.activeChart().chartModel().mainSeries()._visibleBarsCount()

	main_timer = setInterval(() => {
		if (bar_replay_controls_status == false) {
			clearInterval(main_timer);

		} else {
			try {

				//console.log(state)


				if (cc[replay_current_pos]) {


					state.end = state_end - cc_length + (++replay_current_pos);
					state.data = state_data.slice(0, state_end - cc_length + replay_current_pos);

					tvWidget.activeChart().chartModel().mainSeries().bars().restoreState(state)
					tvWidget.activeChart().chartModel().mainSeries().restart()


					tvWidget.activeChart().setVisibleRange({

						from: parseInt(cc[replay_current_pos].value[0]) - resolution * 60 * vcount,
						to: parseInt(cc[replay_current_pos].value[0])
					}, {
						applyDefaultRightMargin: "100px"
					}

					);
				} else {
					bar_replay_controls.innerHTML = '<div data-role="button" class="button-reABrhVR" style="padding:4px"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><path fill="currentColor" fill-rule="evenodd" d="m10.997 6.93 7.834 6.628a.58.58 0 0 1 0 .88l-7.834 6.627c-.359.303-.897.04-.897-.44V7.37c0-.48.538-.743.897-.44Zm8.53 5.749a1.741 1.741 0 0 1 0 2.637l-7.834 6.628c-1.076.91-2.692.119-2.692-1.319V7.37c0-1.438 1.616-2.23 2.692-1.319l7.834 6.628Z"></path></svg></div>'
					bar_replay_controls.setAttribute('title', 'Play');
					bar_replay_controls_status = false

					clearInterval(main_timer)
				}



			} catch (e) {
				bar_replay_controls_status = false
				bar_replay_controls.innerHTML = '<div data-role="button" class="button-reABrhVR" style="padding:4px"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><path fill="currentColor" fill-rule="evenodd" d="m10.997 6.93 7.834 6.628a.58.58 0 0 1 0 .88l-7.834 6.627c-.359.303-.897.04-.897-.44V7.37c0-.48.538-.743.897-.44Zm8.53 5.749a1.741 1.741 0 0 1 0 2.637l-7.834 6.628c-1.076.91-2.692.119-2.692-1.319V7.37c0-1.438 1.616-2.23 2.692-1.319l7.834 6.628Z"></path></svg></div>'
				bar_replay_controls.setAttribute('title', 'Play');

				clearInterval(main_timer)
			}
		}


	}, bar_replay_set_speed)
}

async function replayControls() {




	bar_replay_controls = tvWidget.createButton();
	bar_replay_controls.setAttribute('title', 'Play');
	resolution = tvWidget.activeChart().resolution()

	bar_replay_controls.innerHTML = '<div data-role="button" class="button-reABrhVR" style="padding:4px"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><path fill="currentColor" fill-rule="evenodd" d="m10.997 6.93 7.834 6.628a.58.58 0 0 1 0 .88l-7.834 6.627c-.359.303-.897.04-.897-.44V7.37c0-.48.538-.743.897-.44Zm8.53 5.749a1.741 1.741 0 0 1 0 2.637l-7.834 6.628c-1.076.91-2.692.119-2.692-1.319V7.37c0-1.438 1.616-2.23 2.692-1.319l7.834 6.628Z"></path></svg></div>'


	bar_replay_controls.addEventListener('click', () => {


		bar_replay_controls_status = !bar_replay_controls_status
		if (bar_replay_controls_status) {
			bar_replay_controls.innerHTML = '<div data-role="button" class="button-reABrhVR" style="padding:4px"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><path fill="currentColor" fill-rule="evenodd" d="M10 6h2v16h-2V6ZM9 6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V6Zm7 0h2v16h-2V6Zm-1 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V6Z"></path></svg></div>'
			bar_replay_controls.setAttribute('title', 'Pause');

		} else {
			bar_replay_controls.innerHTML = '<div data-role="button" class="button-reABrhVR" style="padding:4px"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><path fill="currentColor" fill-rule="evenodd" d="m10.997 6.93 7.834 6.628a.58.58 0 0 1 0 .88l-7.834 6.627c-.359.303-.897.04-.897-.44V7.37c0-.48.538-.743.897-.44Zm8.53 5.749a1.741 1.741 0 0 1 0 2.637l-7.834 6.628c-1.076.91-2.692.119-2.692-1.319V7.37c0-1.438 1.616-2.23 2.692-1.319l7.834 6.628Z"></path></svg></div>'
			bar_replay_controls.setAttribute('title', 'Play');

			clearInterval(main_timer)

		}

		if (resolution == "1D" || resolution == "D") {
			resolution = 1440
		} else if (resolution == "1W") {
			resolution = 1440 * 7
		} else if (resolution == "1M") {
			resolution = 1440 * 30
		}


		// tvWidget.activeChart().setVisibleRange({
		// 	from: parseInt(localStorage.getItem('bar_replay_time_from')) + resolution * 60 * 0,
		// 	to: parseInt(cc[0].value[0])
		// }, {
		// 	applyDefaultRightMargin: "50px"
		// }

		// );




		tvBarTimer()





	});







	bar_replay_step = tvWidget.createButton()
	bar_replay_step.innerHTML = '<div data-role="button" class="button-reABrhVR" style="padding:4px"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><path fill="currentColor" fill-rule="evenodd" d="M20 6v16h1V6h-1Zm-3.908 7.628L9.834 7.996A.5.5 0 0 0 9 8.368v11.264a.5.5 0 0 0 .834.372l6.258-5.632a.5.5 0 0 0 0-.744Zm.67 1.487a1.5 1.5 0 0 0 0-2.23l-6.259-5.632C9.538 6.384 8 7.07 8 8.368v11.264c0 1.299 1.538 1.984 2.503 1.115l6.258-5.632Z"></path></svg></div>'
	let vcount = tvWidget.activeChart().chartModel().mainSeries()._visibleBarsCount()

	bar_replay_step.setAttribute('title', 'Forward');
	bar_replay_step.addEventListener('click', () => {


		if (resolution == "1D" || resolution == "D") {
			resolution = 1440
		} else if (resolution == "1W") {
			resolution = 1440 * 7
		} else if (resolution == "1M") {
			resolution = 1440 * 30
		}



		if (cc[replay_current_pos]) {
			state.end = state_end - cc_length + (++replay_current_pos);
			state.data = state_data.slice(0, state_end - cc_length + replay_current_pos);

			tvWidget.activeChart().chartModel().mainSeries().bars().restoreState(state)
			tvWidget.activeChart().chartModel().mainSeries().restart()


			tvWidget.activeChart().setVisibleRange({

				from: parseInt(cc[replay_current_pos].value[0]) - resolution * 60 * vcount,
				to: parseInt(cc[replay_current_pos].value[0])
			}, {
				applyDefaultRightMargin: "100px"
			}

			);
		}



	})



	let dropdown;
	const change_title = (new_title) => {
		dropdown.applyOptions({
			title: new_title
		});
	}



	dropdown = await tvWidget.createDropdown({
		title: '1x',
		tooltip: 'Replay speed',
		items: [
			{
				title: '100x',
				onSelect: () => {
					change_title('100x')
					bar_replay_set_speed = 10
					tvBarTimer()
				},
			},
			{
				title: '10x',
				onSelect: () => {
					change_title('10x')
					bar_replay_set_speed = 100
					tvBarTimer()
				},
			},
			{
				title: '5x',
				onSelect: () => {
					change_title('5x')
					bar_replay_set_speed = 200
					tvBarTimer()
				},
			},

			{
				title: '1x',
				onSelect: () => {
					change_title('1x')
					bar_replay_set_speed = 1000
					tvBarTimer()
				},
			},
			{
				title: '0.5x',
				onSelect: () => {
					change_title('0.5x')
					bar_replay_set_speed = 2000
					tvBarTimer()
				},
			},

			{
				title: '0.1x',
				onSelect: () => {
					change_title('0.1x')
					bar_replay_set_speed = 10000
					tvBarTimer()
				},
			}
		],
	})
}

var bar_replay_step
export const TVChartContainer = () => {
	let symbolsDiv = document.getElementById('symbols');
	let alarmsDiv = document.getElementById('alarms');

	
	async function checkPermission()  {
		if (!('serviceWorker' in navigator)) {
			throw new Error("No support for service worker!")
		}

		if (!('Notification' in window)) {
			throw new Error("No support for notification API");
		}

		if (!('PushManager' in window)) {
			throw new Error("No support for Push API")
		}
	}

	async function registerSW() {

		await navigator.serviceWorker.getRegistrations().then(registrations => {
			for (const registration of registrations) {
				registration.unregister();
			}





		});
		const registration = await navigator.serviceWorker.register('serviceWorker.js?config=' + user_id)

	}

	const requestNotificationPermission = useCallback(() => {
		if ('Notification' in window) {
			Notification.requestPermission().then((permission) => {
				if (permission != "granted") {
					throw new Error("Notification permission not granted")

				}
			})

		}
	})
	async function main()  {
		await checkPermission()

		await registerSW()
	}
	main()
	async function submitAlarm() {

		await requestNotificationPermission()
		$(".alarmMsg").html("")
		let symbol = tvWidget.symbolInterval().symbol.split(":")[1]
		await axios.post(`${base_url}/addToAlarm.php`, {
			user_id: user_id,
			symbol: symbol,

			price: $(".price-target").val(),
			target_type: $(".type-target").val(),
		}, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
			.then(res => {
				if (res.data.status == 1) {

					main()

					$(".price-target").val("")
					$("#myModal").modal("hide")

					alarmsDiv.innerHTML = ""
					res.data.data.forEach((el) => {
						var alarmDiv_ = document.createElement('div');
						alarmDiv_.innerHTML = `<div style="display:flex;justify-content:space-between"  cs="${el.id}" class="alarm">


										<div>
										${el.symbol + " (" + el.price + ")"}
										</div>
										

										

										<a href="javascript:{}"  cs="${el.id}" class="delete-alarm">

										❌
										
										</a>
										
									
										</div>`


							;


						alarmsDiv.appendChild(alarmDiv_);

					})
					$(".delete-alarm").click(function () {
						let id = $(this).attr("cs")
						$.post(`${base_url}/deleteAlarm.php`, {
							alarm_id: id,
						}, function (res) {
							res = JSON.parse(res)
							if (res.status == 1) {
								$(`.alarm[cs='${res.id}']`).parent().remove()

							}

						})
					})

				} else {
					$(".alarmMsg").html(
						res.data.msg)

				}
			});

	}


	localStorage.removeItem("bar_replay_time_from", null)
	localStorage.removeItem("bar_replay_time_to", null)
	localStorage.removeItem("bar_replay_resolution", null)
	localStorage.removeItem("bar_replay_ticker", null)
	let dropdown2
	let dropdown3


	const chartContainerRef = useRef();
	const change_title2 = (new_title) => {
		dropdown2.applyOptions({
			title: new_title
		});
	}
	const change_title = (new_title) => {
		dropdown3.applyOptions({
			title: new_title
		});
	}
	const defaultProps = {
		symbol: '35425587644337450',
		interval: 'D',
		adjustType: 'بدون تعدیل',
		datafeedUrl: 'https://tradingview.sourcearena.ir/bourse-data/',
		libraryPath: '/charting_library/',
		chartsStorageApiVersion: '1.1',
		fullscreen: false,
		autosize: true,
		studiesOverrides: {},
	};
	const jMonths = [
		"فروردین",
		"اردیبهشت",
		"خرداد",
		"تیر",
		"مرداد",
		"شهریور",
		"مهر",
		"آبان",
		"آذر",
		"دی",
		"بهمن",
		"اسفند",
	];



	function initChart() {

		const widgetOptions = {
			symbol: defaultProps.symbol,
			// BEWARE: no trailing slash is expected in feed URL
			datafeed: new window.Datafeeds.UDFCompatibleDatafeed("https://tradingview.sourcearena.ir/bourse-data/" + key),
			interval: defaultProps.interval,
			container: chartContainerRef.current,
			library_path: defaultProps.libraryPath,

			locale: getLanguageFromURL() || 'fa',
			time_frames: [{
				text: "1Y",
				resolution: "12M"
			},
			{
				text: "6M",
				resolution: "6M"
			},
			{
				text: "3M",
				resolution: "3M"
			},
			{
				text: "1W",
				resolution: "W"
			},
			{
				text: "1D",
				resolution: "D"
			},
			],

			padding: 5,

			disabled_features: [],
			enabled_features: ["move_logo_to_main_pane", "bar_replay", "narrow_chart_enabled", "trading_options", "side_toolbar_in_fullscreen_mode", "header_in_fullscreen_mode"],
			timezone: "Asia/Tehran",

			charts_storage_api_version: "1.1",

			theme: localStorage.getItem("tv_theme", "light"),
			fullscreen: defaultProps.fullscreen,
			autosize: defaultProps.autosize,
			studies_overrides: defaultProps.studiesOverrides,
			custom_formatters: {
				dateFormatter: {
					format: (date) => {
						moment.locale('fa');
						return moment(date).format('dddd') + ', ' + moment(date).format('jYYYY/jM/jD');
					},
				},

				tickMarkFormatter: (date, tickMarkType) => {
					switch (tickMarkType) {
						case 'Year':
							return moment(date).format('jYYYY');

						case 'Month':
							return jMonths[moment(date).format('jM') - 1];


						case 'DayOfMonth':
							return moment(date).format('jD');

						case 'Time':
							return date.getUTCHours() + ':' + date.getUTCMinutes();

						case 'TimeWithSeconds':
							return date.getUTCHours() + ':' + date.getUTCMinutes() + ':' + date.getUTCSeconds();
					}

					throw new Error('unhandled tick mark type ' + tickMarkType);
				}
			},
		};

		tvWidget = new widget(widgetOptions);


		tvWidget.onChartReady(async function () {


			tvWidget.headerReady().then(async () => {






				try {
					let category = await axios.get(`${base_url}/loadCategory.php?user_id=${user_id}`, {
						headers: {
							'Content-Type': 'application/json'
						}
					})
						.then(res => res.data)
					category.forEach((el) => {

						var symbolDiv_ = `<li cs="${el.id}"><button class="dropdown-item mf"  cs="${el.id}" type="button" >${el.text}</button></li>`;
						$('#dropdown-menu-2')
							.prepend(symbolDiv_);
						$(`.dm2 .dropdown-item:not('.df')`).click(function () {
							$("#dropdownMenuButton2").text($(this).text())

							$("#dropdownMenuButton2").attr("cs", $(this).attr("cs"))
							$(`#symbols > div`).hide()
							$(`#symbols > div[cs=${$(this).attr("cs")}]`).show()
						})



					})
				} catch (e) {
					console.log(e)

				}

				try {


					symbols = await axios.get(`${base_url}/loadWatchList.php?user_id=${user_id}`, {
						headers: {
							'Content-Type': 'application/json'
						}
					})
						.then(res => res.data)
					let symbol = tvWidget.symbolInterval().symbol.split(":")[1]
					let interval = tvWidget.symbolInterval().interval
					symbolsDiv.innerHTML = ""
					symbols.forEach((el) => {
						var symbolDiv_ = document.createElement('div');
						symbolDiv_.textContent = el.symbol;
						symbolDiv_.setAttribute("cs", el.watchlist_category_id)

						symbolDiv_.addEventListener('click', function () {
							tvWidget.setSymbol(el.symbol, "D");


							Array.from(symbolsDiv.children).forEach(function (symbol) {
								symbol.classList.remove('highlight');
							});
							this.classList.add('highlight');

						});
						symbolsDiv.appendChild(symbolDiv_);
						symbolDiv_.classList.remove('highlight');
						if (symbol == symbolDiv_.innerText) {
							symbolDiv_.classList.add('highlight');

						}
					})

					$(`#symbols > div`).hide()
					$(`#symbols > div[cs=0]`).show()
				} catch (e) { }

				try {


					let alarms = await axios.get(`${base_url}/loadAlarm.php?user_id=${user_id}`, {
						headers: {
							'Content-Type': 'application/json'
						}
					})
						.then(res => res.data)
					let symbol = tvWidget.symbolInterval().symbol.split(":")[1]
					let interval = tvWidget.symbolInterval().interval
					alarmsDiv.innerHTML = ""
					alarms.forEach((el) => {
						var alarmDiv_ = document.createElement('div');
						alarmDiv_.innerHTML = `<div style="display:flex;justify-content:space-between"  cs="${el.id}" class="alarm">


									<div>
									${el.symbol + " (" + el.price + ")"}
									</div>
									

									<a href="javascript:{}"  cs="${el.id}" class="delete-alarm">

									❌
									
									</a>
									</div>`


							;

						alarmsDiv.appendChild(alarmDiv_);

					})
					$(".delete-alarm").click(function () {
						let id = $(this).attr("cs")
						$.post(`${base_url}/deleteAlarm.php`, {
							alarm_id: id,
						}, function (res) {
							res = JSON.parse(res)
							if (res.status == 1) {
								$(`.alarm[cs='${res.id}']`).parent().remove()

							}

						})
					})
				} catch (e) {
					console.log(e)
				}




				dropdown2 = await tvWidget.createDropdown({
					title: defaultProps.adjustType,
					tooltip: 'Adjustment Type',
					items: [{
						title: 'عملکردی',
						onSelect: () => {
							change_title2('عملکردی')
							var s = tvWidget.symbolInterval();
							defaultProps.symbol = s.symbol + '|' + 1;
							defaultProps.adjustType = 'عملکردی';
							tvWidget.setSymbol(defaultProps.symbol, s.interval);
						},
					},

					{
						title: 'بدون تعدیل',
						onSelect: () => {
							change_title2('بدون تعدیل')
							var s = tvWidget.symbolInterval();
							defaultProps.symbol = s.symbol + '|' + 0;
							defaultProps.adjustType = 'بدون تعدیل';

							tvWidget.setSymbol(defaultProps.symbol, s.interval);
						},
					},

					]
				});







				var bar_replay = tvWidget.createButton();

				bar_replay.addEventListener('click', async () => {

					ac = [...tvWidget.activeChart().chartModel().mainSeries().bars()._items]

					replay_current_pos = 0
					if (bar_replay_status == 0) {

						bar_replay.innerHTML = '<div data-role="button"  title="Replay" class="button-reABrhVR" style="padding:4px"; display: inline-flex; align-items: center;"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"> <path fill="none" stroke="blue" d="M13.5 20V9l-6 5.5 6 5.5zM21.5 20V9l-6 5.5 6 5.5z"></path> </svg> </div>'
						tvWidget.activeChart().requestSelectBar().then((time) => {


							tvWidget.activeChart().removeAllStudies();

							state = { ...tvWidget.activeChart().chartModel().mainSeries().bars().state() }
							state_end = state.end
							state_data = state.data
							let length = [...tvWidget.activeChart().chartModel().
								mainSeries().bars()._items].reverse()[0].index
							tvWidget.activeChart().setVisibleRange({
								from: tvWidget.activeChart().getVisibleRange().from,
								to: time
							}, {
								applyDefaultRightMargin: "100px"
							}

							);


							ac.forEach((br, a) => {
								if (br.value[0] > time + 20000) {

									length = length + 1


									let mr = { ...br }
									mr.index = length

									cc.push(mr)
								}
							})
							cc_length = cc.length
							state.end = state_end - cc_length + replay_current_pos;
							state.data = state.data.slice(0, state_end - cc_length);

							tvWidget.activeChart().chartModel().mainSeries().bars().restoreState(state)
							tvWidget.activeChart().chartModel().mainSeries().restart()
							replayControls()


						})
					} else {
						bar_replay.innerHTML = '<div data-role="button" class="button-reABrhVR" style="padding:4px"; display: inline-flex; align-items: center;"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"> <path fill="none" stroke="currentColor" d="M13.5 20V9l-6 5.5 6 5.5zM21.5 20V9l-6 5.5 6 5.5z"></path> </svg>  </div>'
						tvWidget.activeChart().cancelSelectBar();
					}

					bar_replay_status = bar_replay_status == 0 ? 1 : 0

				})

				bar_replay.innerHTML = '<div data-role="button" class="button-reABrhVR" style="padding:4px"; display: inline-flex; align-items: center;"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"> <path fill="none" stroke="currentColor" d="M13.5 20V9l-6 5.5 6 5.5zM21.5 20V9l-6 5.5 6 5.5z"></path> </svg> </div>'
				bar_replay.setAttribute('title', 'Replay');







				if (localStorage.getItem("tv_theme", "dark") == "light") {
					//	tvWidget.chart().applyOverrides({ 'paneProperties.background': 'white', 'paneProperties.backgroundType': 'solid' });
				} else {
					//tvWidget.chart().applyOverrides({ 'paneProperties.background': '#0d082b', 'paneProperties.backgroundType': 'solid' });
				}

				// var iframe = $('#tv_chart_container iframe').contents();

				// setTimeout(function () {
				// 	$('#adjustmentType', iframe).val(adjustmentType);
				// }, 0);

				var s = tvWidget.symbolInterval();

				var chart_loaded = false;
				tvWidget.subscribe('onTick', function (e) {
					chart_loaded = true;





				});
				// setInterval(() => {
				// 	if (bar_replay_status) {
				// 		try {
				// 			state.end = state_end - cc_length + replay_current_pos;
				// 			state.data = state.data.slice(0, state_end - cc_length);

				// 			tvWidget.activeChart().chartModel().mainSeries().bars().restoreState(state)
				// 			tvWidget.activeChart().chartModel().mainSeries().restart()
				// 		}
				// 		catch (e) {

				// 		}
				// 	} 
				// }, 500)

				tvWidget.subscribe("onAutoSaveNeeded", () => tvWidget.saveChartToServer());

				var chart_load_requested = false;
				tvWidget.subscribe('chart_load_requested', function (e) {
					chart_load_requested = true;
					console.log("new data")
				});

				let length = [...tvWidget.activeChart().chartModel().
					mainSeries().bars()._items].reverse()[0].index;
				tvWidget.activeChart().onVisibleRangeChanged().subscribe(
					null,
					({ from, to }) => {


						ac = [...tvWidget.activeChart().chartModel().mainSeries().bars()._items]


					}
				);

				var marks = localStorage.getItem("marks")

				var theme = tvWidget.getTheme();

				let color = localStorage.getItem("tv_theme", "dark") == "dark" ? "white" : "black";


				let theme_btn = tvWidget.createButton({
					align: "right",
					'title': 'Change Theme'
				})
				let alarm_btn = tvWidget.createButton({
					'title': 'Set Alarm'
				})


				let collapseButton = tvWidget.createButton();
				collapseButton.textContent = 'Add WatchList';
				collapseButton.title = 'Add to WatchList';
				symbolsDiv = document.getElementById('symbols');

				alarm_btn.addEventListener('click', async function () {
					$("#myModal").modal("show")

				})
				alarm_btn.innerHTML =
					`<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="${color}" version="1.1" id="Capa_1" width="25px" height="21px" viewBox="0 0 979.1 979.1" xml:space="preserve">
					<g><path d="M489.589,979.1c62.401,0,113.601-48.3,118.201-109.6h-236.4C375.99,930.8,427.089,979.1,489.589,979.1z"/><path d="M867.09,759.2c-75.8-83-81-209.301-81.3-234.301V263c0-145.3-117.8-263-263-263h-66.6c-145.3,0-263,117.8-263,263v267.5   c-1.1,34.5-10,150.7-81.2,228.7c-17.6,19.3-4,50.199,22.1,50.199h248.5H596.49h248.5C871.19,809.5,884.69,778.5,867.09,759.2z"/></g>
					</svg>`

				collapseButton.addEventListener('click', async function () {
					// Get the chart container and watchlist divs
					let chartContainer = document.getElementById('tv_chart_container');
					let watchlist = document.getElementById('senioriz-watchlist');
					let symbol = tvWidget.symbolInterval().symbol.split(":")[1]
					let interval = tvWidget.symbolInterval().interval




					if (symbols.filter(item => item.symbol == symbol && item.watchlist_category_id == $("#dropdownMenuButton2").attr("cs")).length == 0) {
						axios.post(`${base_url}/addToWatchlist.php`, {
							user_id: user_id,
							symbol: symbol,
							watchlist_id: $("#dropdownMenuButton2").attr("cs")
						}, {
							headers: {
								'Content-Type': 'application/json'
							}
						})
							.then(res => {
								if (res.data.status == 1) {
									var symbolDiv_ = document.createElement('div');
									symbolDiv_.textContent = symbol;
									symbolDiv_.setAttribute("cs", $("#dropdownMenuButton2").attr("cs"))
									symbolDiv_.addEventListener('click', function () {
										tvWidget.setSymbol(symbol, interval);
										Array.from(symbolsDiv.children).forEach(function (symbol) {
											symbol.classList.remove('highlight');
										});
										this.classList.add('highlight');

									});
									symbolsDiv.appendChild(symbolDiv_);
									Array.from(symbolsDiv.children).forEach(function (symbol) {
										symbol.classList.remove('highlight');
									});
									symbolDiv_.classList.add('highlight');


									symbols = res.data.data
									symbolsDiv = document.getElementById('symbols');
									$(`#symbols > div[cs=${$("#dropdownMenuButton2").attr("cs")}]`).show()

								}

							})


					}


				});
				let events_btn = tvWidget.createButton({
					align: "right"
				})
				theme_btn.innerHTML = ('<div class="switchtheme"><svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="28" height="28" x="0" y="0" viewBox="0 0 48 48" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="M12.1 12c.2.3.5.4.8.4.2 0 .4-.1.6-.2.4-.3.5-1 .2-1.4l-.6-.8c-.3-.4-.9-.5-1.4-.2-.4.3-.5 1-.2 1.4zM7 27.8l-1 .3c-.5.2-.8.7-.6 1.3.1.4.5.7 1 .7h.3l1-.3c.5-.2.8-.7.6-1.3-.2-.5-.8-.8-1.3-.7zM22.1 9.5c.6 0 1-.4 1-1v-1c0-.6-.4-1-1-1s-1 .4-1 1v1c0 .6.4 1 1 1zM6.1 19.9s1.2.4 1.3.4c.4 0 .8-.3 1-.7.2-.5-.1-1.1-.6-1.3l-1-.3c-.5-.2-1.1.1-1.3.6-.3.5 0 1.1.6 1.3zM12.1 36l-.6.8c-.3.4-.2 1.1.2 1.4.2.1.4.2.6.2.3 0 .6-.1.8-.4l.6-.8c.3-.4.2-1.1-.2-1.4-.4-.4-1-.3-1.4.2zM42.3 22.2c-.3-.2-.7-.3-1-.2-.9.3-1.9.5-2.9.3-3.2-.5-5.5-3.5-5-6.7.2-1.2.7-2.3 1.6-3.2.3-.3.3-.7.2-1-.1-.4-.5-.6-1-.7-2.4-.4-4.8.2-6.8 1.4-1.6-.7-3.4-1.1-5.2-1.1-7.2-.1-13.1 5.8-13.1 13S15 37.1 22.2 37.1c4.5 0 8.7-2.3 11.1-6.1 4.4-.2 8.3-3.3 9.4-7.8 0-.4-.1-.8-.4-1zM22.2 35.1c-6.1 0-11.1-5-11.1-11.1s5-11.1 11.1-11.1c1.2 0 2.3.2 3.4.5-1.6 1.5-2.6 3.5-3 5.8-.8 5.5 2.9 10.6 8.3 11.5-2.1 2.8-5.3 4.4-8.7 4.4zm9.3-6.2c-4.5-.7-7.6-4.8-7-9.3.6-4 3.9-6.9 7.8-7-.5.8-.8 1.8-.9 2.8-.6 4.3 2.4 8.3 6.7 9 .7.1 1.3.1 2 0-1.5 3.1-5 5-8.6 4.5zM32 36c-.3-.4-.9-.5-1.4-.2-.4.3-.5 1-.2 1.4l.6.8c.2.3.5.4.8.4.2 0 .4-.1.6-.2.4-.3.5-1 .2-1.4zM22.1 38.5c-.6 0-1 .4-1 1v1c0 .6.4 1 1 1s1-.4 1-1v-1c0-.6-.5-1-1-1z" fill="' + color + '" opacity="1" data-original="#000000"></path></g></svg></div>')
				theme_btn.addEventListener('click', function (e) {
					if (theme == "dark") {




						localStorage.setItem("tv_theme", "light")
						this.innerHTML = (`<div class="switchtheme"><svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="28" height="28" x="0" y="0" viewBox="0 0 48 48" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="M12.1 12c.2.3.5.4.8.4.2 0 .4-.1.6-.2.4-.3.5-1 .2-1.4l-.6-.8c-.3-.4-.9-.5-1.4-.2-.4.3-.5 1-.2 1.4zM7 27.8l-1 .3c-.5.2-.8.7-.6 1.3.1.4.5.7 1 .7h.3l1-.3c.5-.2.8-.7.6-1.3-.2-.5-.8-.8-1.3-.7zM22.1 9.5c.6 0 1-.4 1-1v-1c0-.6-.4-1-1-1s-1 .4-1 1v1c0 .6.4 1 1 1zM6.1 19.9s1.2.4 1.3.4c.4 0 .8-.3 1-.7.2-.5-.1-1.1-.6-1.3l-1-.3c-.5-.2-1.1.1-1.3.6-.3.5 0 1.1.6 1.3zM12.1 36l-.6.8c-.3.4-.2 1.1.2 1.4.2.1.4.2.6.2.3 0 .6-.1.8-.4l.6-.8c.3-.4.2-1.1-.2-1.4-.4-.4-1-.3-1.4.2zM42.3 22.2c-.3-.2-.7-.3-1-.2-.9.3-1.9.5-2.9.3-3.2-.5-5.5-3.5-5-6.7.2-1.2.7-2.3 1.6-3.2.3-.3.3-.7.2-1-.1-.4-.5-.6-1-.7-2.4-.4-4.8.2-6.8 1.4-1.6-.7-3.4-1.1-5.2-1.1-7.2-.1-13.1 5.8-13.1 13S15 37.1 22.2 37.1c4.5 0 8.7-2.3 11.1-6.1 4.4-.2 8.3-3.3 9.4-7.8 0-.4-.1-.8-.4-1zM22.2 35.1c-6.1 0-11.1-5-11.1-11.1s5-11.1 11.1-11.1c1.2 0 2.3.2 3.4.5-1.6 1.5-2.6 3.5-3 5.8-.8 5.5 2.9 10.6 8.3 11.5-2.1 2.8-5.3 4.4-8.7 4.4zm9.3-6.2c-4.5-.7-7.6-4.8-7-9.3.6-4 3.9-6.9 7.8-7-.5.8-.8 1.8-.9 2.8-.6 4.3 2.4 8.3 6.7 9 .7.1 1.3.1 2 0-1.5 3.1-5 5-8.6 4.5zM32 36c-.3-.4-.9-.5-1.4-.2-.4.3-.5 1-.2 1.4l.6.8c.2.3.5.4.8.4.2 0 .4-.1.6-.2.4-.3.5-1 .2-1.4zM22.1 38.5c-.6 0-1 .4-1 1v1c0 .6.4 1 1 1s1-.4 1-1v-1c0-.6-.5-1-1-1z" fill="black" opacity="1" data-original="#000000"></path></g></svg></div>`)
						theme = "light"
						tvWidget.changeTheme("light").then(() => {
							//tvWidget.chart().applyOverrides({ 'paneProperties.background': 'white', 'paneProperties.backgroundType': 'solid' });
						});
						events_btn.innerHTML = (`<div style="cursor:pointer;color:${theme == "dark" ? "white" : "black"}">پنهان کردن علائم</div>`)

					} else {
						this.innerHTML = (`<div class="switchtheme"><svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="28" height="28" x="0" y="0" viewBox="0 0 48 48" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="M12.1 12c.2.3.5.4.8.4.2 0 .4-.1.6-.2.4-.3.5-1 .2-1.4l-.6-.8c-.3-.4-.9-.5-1.4-.2-.4.3-.5 1-.2 1.4zM7 27.8l-1 .3c-.5.2-.8.7-.6 1.3.1.4.5.7 1 .7h.3l1-.3c.5-.2.8-.7.6-1.3-.2-.5-.8-.8-1.3-.7zM22.1 9.5c.6 0 1-.4 1-1v-1c0-.6-.4-1-1-1s-1 .4-1 1v1c0 .6.4 1 1 1zM6.1 19.9s1.2.4 1.3.4c.4 0 .8-.3 1-.7.2-.5-.1-1.1-.6-1.3l-1-.3c-.5-.2-1.1.1-1.3.6-.3.5 0 1.1.6 1.3zM12.1 36l-.6.8c-.3.4-.2 1.1.2 1.4.2.1.4.2.6.2.3 0 .6-.1.8-.4l.6-.8c.3-.4.2-1.1-.2-1.4-.4-.4-1-.3-1.4.2zM42.3 22.2c-.3-.2-.7-.3-1-.2-.9.3-1.9.5-2.9.3-3.2-.5-5.5-3.5-5-6.7.2-1.2.7-2.3 1.6-3.2.3-.3.3-.7.2-1-.1-.4-.5-.6-1-.7-2.4-.4-4.8.2-6.8 1.4-1.6-.7-3.4-1.1-5.2-1.1-7.2-.1-13.1 5.8-13.1 13S15 37.1 22.2 37.1c4.5 0 8.7-2.3 11.1-6.1 4.4-.2 8.3-3.3 9.4-7.8 0-.4-.1-.8-.4-1zM22.2 35.1c-6.1 0-11.1-5-11.1-11.1s5-11.1 11.1-11.1c1.2 0 2.3.2 3.4.5-1.6 1.5-2.6 3.5-3 5.8-.8 5.5 2.9 10.6 8.3 11.5-2.1 2.8-5.3 4.4-8.7 4.4zm9.3-6.2c-4.5-.7-7.6-4.8-7-9.3.6-4 3.9-6.9 7.8-7-.5.8-.8 1.8-.9 2.8-.6 4.3 2.4 8.3 6.7 9 .7.1 1.3.1 2 0-1.5 3.1-5 5-8.6 4.5zM32 36c-.3-.4-.9-.5-1.4-.2-.4.3-.5 1-.2 1.4l.6.8c.2.3.5.4.8.4.2 0 .4-.1.6-.2.4-.3.5-1 .2-1.4zM22.1 38.5c-.6 0-1 .4-1 1v1c0 .6.4 1 1 1s1-.4 1-1v-1c0-.6-.5-1-1-1z" fill="white" opacity="1" data-original="black"></path></g></svg></div>`)
						theme = "dark"

						localStorage.setItem("tv_theme", "dark")
						tvWidget.changeTheme("dark").then(() => {
							//tvWidget.chart().applyOverrides({ 'paneProperties.background': '#0d082b', 'paneProperties.backgroundType': 'solid' });
						});
						events_btn.innerHTML = (`<div style="cursor:pointer;color:${theme == "dark" ? "white" : "black"}">پنهان کردن علائم</div>`)

					}

				})

				events_btn.innerHTML = (`<div style="cursor:pointer;color:${theme == "dark" ? "white" : "black"}">پنهان کردن علائم</div>`)

				events_btn.addEventListener('click', function (e) {


					if (marks ?? true) {
						tvWidget.activeChart().clearMarks();
						localStorage.setItem("marks", false)
						marks = false
					} else {
						tvWidget.activeChart().refreshMarks();
						localStorage.setItem("marks", true)
						marks = true
					}
				});


			});
		});

		return () => {
			tvWidget.remove();
		};

	}

	useEffect(() => {
		registerSW()
		symbolsDiv = document.getElementById('symbols');
		alarmsDiv = document.getElementById('alarms');

		initChart()


	});

	return (
		<div>
			<div
				id="tv_chart_container"
				ref={chartContainerRef}
				className={'TVChartContainer'}
			/>
			<div className="modal" id="myModal" style={{ direction: "rtl" }}>
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">

						<div className="modal-header">
							<h4 className="modal-title">تنظیم آلارم</h4>
						</div>

						<div className="modal-body" style={{ textAlign: "right" }}>
							<p>
								جهت دریافت نوتیفیکیشن لطفا دسترسی مرورگر را فعال کنید
							</p>
							<div style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center'

							}} >



								<div>
									<label style={{ display: "block" }}>تارگت قیمت</label>
									<input className="price-target" placeholder="" style={{
										borderRadius: '10px',
										paddingLeft: '20px',
										marginBottom: '20px',
										width: '150px',
										direction: 'ltr',
										color: 'white',
										background: '#2a2e39',
										border: 0,
										height: '35px'
									}} />
								</div>
								<div>
									<label style={{ display: "block" }}>نوع هدف</label>
									<select className="type-target" placeholder="" style={{
										borderRadius: '10px',
										paddingLeft: '20px',
										marginBottom: '20px',
										width: '250px',
										direction: 'ltr',
										color: 'white',
										background: '#2a2e39',
										border: 0,
										height: '35px'
									}} >

										<option value="0">Crossing</option>
										<option value="1">Crossing up</option>
										<option value="2">Crossing down</option>
										<option value="3">Greater than</option>

									</select>
								</div>
							</div>

							<div style={{ color: "red" }} className='alarmMsg'></div>
						</div>

						<div className="modal-footer">
							<button type="button" className="btn btn-success" onClick={() => { submitAlarm() }}>ثبت</button>
							<button type="button" className="btn btn-danger" data-dismiss="modal" onClick={() => { $("#myModal").modal("hide") }}>بستن</button>
						</div>

					</div>
				</div>
			</div>
		</div>


	);
}