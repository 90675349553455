import './App.css';
import { TVChartContainer } from './components/TVChartContainer/index';
import { version } from './charting_library';


 
import React from "react";

import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Home from "./pages";
import Bourse from "./pages/bourse";
import Crypto from "./pages/crypto";




 
function App() {
    return (
        <Router>
    
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="/bourse" element={<Bourse />} />
                <Route path="/crypto" element={<Crypto />} />
              
            </Routes>
        </Router>
    );
}
 
export default App;

