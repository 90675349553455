import React, { useEffect, useState } from "react";
import axios from 'axios';

const user_id = 102;
const base_url = "https://panel.chartshield.com/server";

export const DPDOWN = (params) => {
    const [dropTitle, setDropTitle] = useState("Watchlist");

    function createCategory() {
        $("#myModal2").modal("show")

    }

    async function saveCategory() {
        $(".alarmMsg2").html("")
        await axios.post(`${base_url}/addCategory.php`, {
            user_id: user_id,
            type: params.type,
            text: $(".name").val(),
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                if (res.data.status == 1) {



                    $(".name").val("")
                    $("#myModal2").modal("hide")
                    $(`.dm2 .dropdown-item.mf`).remove()
                    res.data.data.forEach((el) => {
                        
                        var symbolDiv_ = `<li cs="${el.id}"><button cs="${el.id}" class="dropdown-item mf" type="button" >${el.text}</button></li>`;
                        $('#dropdown-menu-2')
                        .prepend(symbolDiv_);
                        $(`.dm2 .dropdown-item:not('.df')`).click(function(){
                            $("#dropdownMenuButton2").text($(this).text())
                            $("#dropdownMenuButton2").attr("cs",$(this).attr("cs"))
                            $(`#symbols > div`).hide()
                            $(`#symbols > div[cs=${$(this).attr("cs")}]`).show()
                        })
                        
                        
                    })
                   

                } else {
                    $(".alarmMsg2").html(
                        res.data.msg)

                }
            });
    }

    return (
        <div className="dropdown senioriz-ww">
            <div className="d-flex align-items-center justify-content-between">

                <div className="dropdown">
                    <button className="btn btn-secondary dropdown-toggle btn-tradingview" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="true">
                        {dropTitle}
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton" data-popper-placement="bottom-start">

                        <li><button className="dropdown-item" type="button" onClick={() => { setDropTitle('Alarms') }}>Alarms</button></li>
                        <li>
                            <button className="dropdown-item" type="button" onClick={() => { setDropTitle('Watchlist') }}>Watchlist</button>
                        </li>

                    </ul>

                </div>


                <div className="dropdown" style={{ display: dropTitle != 'Watchlist' ? 'none' : '' }}>
                    <button cs="0" className="btn btn-secondary dropdown-toggle btn-tradingview" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="true">
                    Default
                    </button>
                    <ul className="dropdown-menu dm2" id="dropdown-menu-2" aria-labelledby="dropdownMenuButton" data-popper-placement="bottom-start">

                        <li cs="0"><button cs="0" className="dropdown-item" type="button">Default</button></li>
                        <li><button className="dropdown-item df" type="button" onClick={() => { createCategory() }}>Create New</button></li>


                    </ul>

                </div>
            </div>

            <div id="symbols" style={{ display: dropTitle != 'Watchlist' ? 'none' : '' }}>

            </div>

            <div id="alarms" style={{ display: dropTitle != 'Alarms' ? 'none' : '' }}>

            </div>


            <div className="modal" id="myModal2" style={{ direction: "rtl" }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h4 className="modal-title">ایجاد واچ لیست</h4>
                        </div>

                        <div className="modal-body" style={{ textAlign: "right" }}>
                            <p>
                                جهت ایجاد واچ لیست، نام دلخواه ان را وارد کنید
                            </p>
                            <div>
                                <label style={{ display: "block" }}>نام</label>
                                <input className="name" placeholder="" style={{
                                    borderRadius: '10px',
                                    paddingLeft: '20px',
                                    marginBottom: '20px',
                                    width: '150px',
                                    direction: 'ltr',
                                    color: 'white',
                                    background: '#2a2e39',
                                    border: 0,
                                    height: '35px'
                                }} />
                            </div>

                            <div style={{ color: "red" }} className='alarmMsg2'></div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={() => { saveCategory() }}>ثبت</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={() => { $("#myModal2").modal("hide") }}>بستن</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>




    );
}

